import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { pagesLinks } from "../../../../pages-data/_V2/common/links"
// import CoffeeIcon from '../../../../components/_V2/BusinessTypes/assets/svg/CoffeeIcon'
// import CafeIcon from '../../../../components/_V2/BusinessTypes/assets/svg/CafeIcon'
// import RestaurantIcon from '../../../../components/_V2/BusinessTypes/assets/svg/RestaurantIcon'
import componentStyles from "../../../../components/_V2/BusinessTypes/styles.module.scss"
import {
	Data,
	BUSINESS_TYPES,
} from "../../../../components/_V2/BusinessTypes/types"

export const data: Data = {
	theme: "white",
	type: "compact",
	changeTitles: true,
	title: "-",
	subTitle: "-",
	readMore: "Смотреть интервью",
	businessType: [
		{
			type: BUSINESS_TYPES.HOCKEY,
			tabName: pagesLinks.businessTypes.stadiums.text,
			// link: pagesLinks.businessTypes.stadiums,
			// icon: CoffeeIcon,
			name: "г. Магнитогорск, клуб КХЛ Металлург",
			details: (
				<span>
					12 кассовых точек
					<br />
					200 заказов за 17 минут на каждой точке продаж.
				</span>
			),
			logo: (
				<StaticImage
					className={componentStyles.businessLogo}
					src="./assets/business-stadion-logo-1.png"
					alt="хоккейная арена лого"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			photo: (
				<StaticImage
					className={componentStyles.businessLogo}
					src="./assets/business-stadion-1.png"
					alt="хоккейная арена"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),
			cardTitle: "Хоккейная арена",
			cardSubtitle: "Быстрая обработка заказов",
		},
		{
			type: BUSINESS_TYPES.MUSIC,
			tabName: pagesLinks.businessTypes.stadiums.text,
			// link: pagesLinks.businessTypes.coffeehouse,
			// icon: CafeIcon,
			name: "Фестиваль электронной музыки Outline",
			details: (
				<span>
					19 кассовых точек
					<br />3 дня круглосуточной работы системы.
				</span>
			),
			logo: (
				<StaticImage
					className={componentStyles.businessLogo}
					src="./assets/business-stadion-logo-2.png"
					alt="музыкальный фестиваль лого"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			photo: (
				<StaticImage
					className={componentStyles.businessLogo}
					src="./assets/business-stadion-2.png"
					alt="музыкальный фестиваль"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),
			cardTitle: "Музыкальный фестиваль",
			cardSubtitle: "Бесперебойная работа баров на музыкальных фестивалях",
		},
		{
			type: BUSINESS_TYPES.GASTRONOMY,
			tabName: pagesLinks.businessTypes.stadiums.text,
			// link: pagesLinks.businessTypes.coffeehouse,
			// icon: RestaurantIcon,
			name: "Международный фестиваль Taste of Moscow",
			details: (
				<span>
					23 кассовых точки
					<br />
					Более 20 тысяч посетителей
				</span>
			),
			logo: (
				<StaticImage
					className={componentStyles.businessLogo}
					src="./assets/business-stadion-logo-3.png"
					alt="гастрономический фестиваль лого"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			photo: (
				<StaticImage
					className={componentStyles.businessLogo}
					src="./assets/business-stadion-3.png"
					alt="гастрономический фестиваль"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),
			cardTitle: "Гастрономический фестиваль",
			cardSubtitle: "Городские фестивали еды и гастромаркеты",
		},
	],
}

export default data
