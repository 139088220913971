import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 490,
			tabName: "Для гостей",
			desktopTitle: "Простой интерфейс",
			desktopContent: (
				<p>
					Найти персонал трудно, научить всему&nbsp;&mdash; долго. Понятный
					интерфейс сэкономит время для более важных дел.
				</p>
			),
			detailLink: pagesLinks.terminal,
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/stadion-interface-1.png"
					alt="кассовый терминал"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Режимы",
			desktopTitle: "Быстрый приём заказов",
			desktopContent: (
				<p>
					3&nbsp;клика до&nbsp;оплаты. Плохой интернет? Касса может работать
					автономно
				</p>
			),
			detailLink: pagesLinks.terminal,
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/stadion-interface-2.png"
					alt="касса для фестиваля еды"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Типы оплат",
			desktopTitle: "Приём всех типов оплат",
			desktopContent: (
				<p>
					Оплата наличными или картой в&nbsp;несколько действий
					и&nbsp;последующий корректный учёт это именно&nbsp;то, чего так
					не&nbsp;хватало малым форматам бизнеса и&nbsp;это&nbsp;то, что
					предлагает программа Quick Resto.
				</p>
			),
			detailLink: pagesLinks.terminal,
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/stadion-interface-3.png"
					alt="оплата на кассе"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}
