import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"
import implementationStyles from "../common/implementation-feature/styles.module.scss"

import { pagesLinks } from "../../common/links"
import optimizeStyles from "../common/optimize-feature/styles.module.scss";

export default [
	{
		title: "Комплексная автоматизация массовых мероприятий",
		description: (
			<>
				<p>
					Оптимизируй расходы на&nbsp;оборудование для дарк китчен:
					не&nbsp;нужно тратить время на&nbsp;поиск и&nbsp;настройку,
					в&nbsp;Quick Resto есть готовый комплект оборудования для старта
					бизнеса.
				</p>
				<br />
				<p>От&nbsp;42&nbsp;000&nbsp;₽</p>
				<ul>
					<li>
						Тикет-Принтер Атол RP 326 (Ethernet).
					</li>
					<li>
						Apple iPad Mini 2, б/у.
					</li>
					<li>
						Стойка для iPad Mini.
					</li>
					<li>
						Маршрутизатор Keenetic 4G KN-1211.
					</li>
				</ul>
			</>
		),
		img: (
			<StaticImage
				className={optimizeStyles.image}
				src="../common/optimize-feature/optimize-feature.png"
				alt="кассовое оборудование"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: optimizeStyles.feature,
		theme: "white",
		actionText: "Подробнее",
		actionLink: pagesLinks.shop.index.href,
	},
	{
		title: "Внедрение автоматизации массовых мероприятий  под ключ",
		description: (
			<>
				<p>От первой продажи до масштабирования.</p>
				<p>Quick Resto даст все инструменты:</p>
				<ul>
					<li>Приложения для управления и продаж</li>
					<li>Отчёты по продажам, блюдам, сотрудникам</li>
					<li>Складской учёт и технологические карты</li>
					<li>CRM-система и аналитика</li>
					<li>Сервис: установка, настройка, обучение</li>
					<li>Круглосуточная техподдержка</li>
				</ul>
			</>
		),

		img: (
			<StaticImage
				className={implementationStyles.image}
				src="./assets/business-stadion-2.png"
				alt="Алексей Гаврилов"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: implementationStyles.feature,
		theme: "white",

		backdrop: (
			<div className={implementationStyles.featureTopRightTrapezeBackdrop} />
		),
	},
]
