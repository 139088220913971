import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { pagesLinks } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"

export default {
	desktopTitle: (
		<span>Автоматизация массовых мероприятий&nbsp;</span>
	),
	price: "от 1990 ₽/мес",
	subTitle: (
		<p>
			Quick Resto – готовое решение для автоматизации точек питания на
			фестивалях или стадионах
		</p>
	),
	figure: (
		<StaticImage
			className={styles.image}
			src="./assets/stadium-presentation.png"
			alt="автоматизация точек питания"
			objectFit="contain"
			objectPosition="center"
			placeholder="blurred"
			quality={90}
		/>
	),
	cta: pagesLinks.registration.text,
	ctaLink: pagesLinks.registration,
	desktopCtaDetails:
		"За 10 минут, без дорогого оборудования, с удобным интерфейсом. И две недели бесплатно.",
}
