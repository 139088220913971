import React, {HTMLAttributeAnchorTarget, SVGProps} from 'react'

type ColorTheme = (
    'white' | 'blueSky' | 'grayLight'
    );

export enum BUSINESS_TYPES {
    COFFEE_SHOP = 'coffeeShop',
    RESTAURANT = 'restaurant',
    BAR = 'bar',
    BAKERY = 'bakery',
    CANTEEN = 'canteen',
    MULTI = 'multi',
    FRANCHISE = 'franchise',
    DARK_KITCHEN = 'darkKitchen',
    CAFE = 'cafe',
    HOCKEY = 'hockey',
    MUSIC = 'music',
    GASTRONOMY = 'gastronomy',
    FOODTRACK = 'foodtrack'
}

export enum BUBBLES_TYPES {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
  QUATERNARY = 'QUATERNARY',
  PYATERIC = 'PYATERIC',
  HEX = 'HEX',
  LINEAR = 'LINEAR'
}

interface businessTypeEntity {
    type : BUSINESS_TYPES
    tabName : string
    link? : { href : string, useGatsbyLink : boolean, text? : string, target?: HTMLAttributeAnchorTarget }
    icon? : React.JSXElementConstructor<SVGProps<SVGSVGElement>>,
    name : string|JSX.Element
    details : string|JSX.Element
    detailsTablet? : string|JSX.Element
    detailsMobile? : string|JSX.Element
    logo? : JSX.Element,
    photo : JSX.Element,
    photoCaption? : string|JSX.Element,
    cardTitle? : string|JSX.Element,
    cardSubtitle? : string|JSX.Element,
    bubbleType?: BUBBLES_TYPES;
    nameClass?: string;
}

export interface Data {
    title : string;
    titleClass?: string;
    subTitle : string
    readMore : string
    theme: ColorTheme
    type?: 'compact'
    changeTitles?: boolean
    hideTabsOnMobile?: boolean
    showArrowsOnMobile?: boolean
    businessType : Array<businessTypeEntity>
}
