import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 440,
			tabName: "Аналитика",
			desktopTitle: "Онлайн-система управления автоматизацией",
			desktopContent: (
				<p>
					Принимай решения на&nbsp;основе данных. Облачный бэк-офис Quick
					Resto&nbsp;&mdash; единая система для управления продажами, складом,
					кухней, персоналом, финансами и&nbsp;поставщиками. Есть как наглядные
					виджеты для оперативного среза, так и&nbsp;детальные отчёты: поможем
					принять правильное решение на&nbsp;любой стадии бизнеса
					и&nbsp;повысить продажи.
				</p>
			),
			detailLink: pagesLinks.reports,
			mediaContent: (
				<StaticImage
					className={mediaContent.image1}
					src="./assets/stadion-analytics-1.png"
					alt="программа учета продаж"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 440,
			tabName: "Отчеты",
			desktopTitle: "Отчёты: когда нужно копнуть глубже",
			desktopContent: (
				<p>
					Сводные таблицы по&nbsp;типам оплат, кассирам, блюдам и&nbsp;другим
					параметрам. Если запрос нестандартный, поможет конструктор отчётов.
					Посмотреть чеки клиента, оплаченные банковской картой, по&nbsp;акции
					&laquo;1+1&raquo;, ещё и&nbsp;за&nbsp;прошлый месяц? Не&nbsp;проблема!
				</p>
			),
			detailLink: pagesLinks.reports,
			mediaContent: (
				<StaticImage
					className={mediaContent.image2}
					src="./assets/stadion-analytics-2.png"
					alt="отчеты по продажам"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 440,
			tabName: "Склад",
			desktopTitle: "Всё по полочкам",
			desktopContent: (
				<p>
					Возьми под контроль весь цикл товарооборота: от&nbsp;закупок
					и&nbsp;прихода до&nbsp;списания, актов разбора и&nbsp;переработки.
					Система в&nbsp;режиме реального времени показывает остатки, недостачи
					и&nbsp;излишки. И&nbsp;подскажет, когда нужно пополнить запасы.
				</p>
			),
			detailLink: pagesLinks.warehouse,
			mediaContent: (
				<StaticImage
					className={mediaContent.image3}
					src="./assets/stadion-analytics-3.png"
					alt="складской учет для точки питания"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}
