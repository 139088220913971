import React, { useRef, useState } from "react";
import classNames from "classnames";
import { showElemFullyInScrollContainer } from "../../../utils/showElemFullyInScrollContainer";

import { useHorizontalScrollOnWheel } from "../../../hooks/useHorizontalScrollOnWheel";

import AdaptiveLink from "../../../components/_V2/AdaptiveLink";

import ArrowLeftIcon from "../../../assets/_V2/svg/ArrowLeftIcon";
import ArrowRightIcon from "../../../assets/_V2/svg/ArrowRightIcon";
import ButtonArrow from "../../../assets/svg/_V2/ButtonArrow";

import { BUBBLES_TYPES, Data } from "./types";
import styles from "./styles.module.scss";
import AdaptiveWrapText from "../AdaptiveWrapText";
import { useLocalizationContext } from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
	data: Data
}

export default function BusinessTypes(props: Props) {
	const localizationContext = useLocalizationContext()

	const data = props.data

	const containerRef = useRef<HTMLDivElement>(null)

	useHorizontalScrollOnWheel(containerRef, "auto")

	const [story, setStory] = useState(data.businessType[0])

	const setNextStory = () => {
		const newIndex = data.businessType.indexOf(story) + 1

		if (newIndex >= data.businessType.length) {
			setStory(data.businessType[0])

			return
		}

		setStory(data.businessType[newIndex])
	}

	const setPrevStory = () => {
		const newIndex = data.businessType.indexOf(story) - 1

		if (newIndex < 0) {
			setStory(data.businessType[data.businessType.length - 1])

			return
		}

		setStory(data.businessType[newIndex])
	}

	const setStoryPosition = (storyData, evt) => {
		showElemFullyInScrollContainer(
			evt.currentTarget,
			evt.currentTarget.parentNode
		)

		setStory(storyData)
	}

	const isTypeCompact = data.type === "compact"

	const renderArrowsInCompact = () => (
		<div className={styles.businessActions}>
			<button className={styles.businessAction} onClick={setPrevStory}>
				<ArrowLeftIcon />
			</button>

			<button className={styles.businessAction} onClick={setNextStory}>
				<ArrowRightIcon />
			</button>
		</div>
	)

	const renderTabs = () => (
		<div className={styles.businessTypes} ref={containerRef}>
			{data.businessType.map(business => (
				<button
					className={classNames(styles.businessTypeButton, {
						[styles.businessTypeButton_active]: story.type === business.type,
					})}
					type="button"
					onClick={e => {
						setStoryPosition(business, e)
					}}
					key={business.type}
				>
					{business.icon && <business.icon className={styles.businessIcon} />}
					<p className={styles.businessTypeName}>{business.tabName}</p>
				</button>
			))}
		</div>
	)

	return (
		<section
			className={classNames(
				styles.section,
				styles[data.theme],
				isTypeCompact ? styles.sectionCompact : "",

				props.className
			)}
		>
			<div className={styles.content}>
				<div className={styles.heading}>
					<p className={styles.subTitle}>
						{data.changeTitles ? story.cardSubtitle : data.subTitle}
					</p>
					<h2 className={classNames(styles.title, styles?.title__kz)}>
						{data.changeTitles ? story.cardTitle : data.title}
					</h2>

					{!isTypeCompact && data.businessType.length > 1 && renderTabs()}
				</div>

				<article
					className={classNames(
						styles.businessStory,
						isTypeCompact ? styles.businessStoryCompact : ""
					)}
				>
					<div className={styles.businessAbout}>
						<div className={styles.businessIdentity}>
							<div
								className={classNames(styles.businessLogoContainer, {
									[styles.businessLogoContainer__noLogo]: !story.logo,
								})}
							>
								{story.logo && story.logo}
							</div>
							<h3 className={classNames(styles.businessName, story?.nameClass)}>{story.name}</h3>
						</div>
						{/* <p className={styles.businessDetails}>{}</p> */}
						{/* <AdaptiveWrapText
                            className={styles.businessDetails}
                            desktop={story.details}
                            tablet={story.detailsTablet}
                            mobile={story.detailsMobile}
                        /> */}

						<AdaptiveWrapText
							className={styles.businessDetails}
							desktop={story.details}
							tablet={story.detailsTablet}
							mobile={story.detailsMobile}
						>
							<span>{story.details}</span>
						</AdaptiveWrapText>
					</div>

					<noindex>
						<div
							className={classNames(
								styles.businessAbout,
								styles.businessAboutCompact
							)}
						>
							<div
								className={classNames(styles.businessLogoContainer, {
									[styles.businessLogoContainer__noLogo]: !story.logo,
								})}
							>
								{story.logo && story.logo}
							</div>

							<div>
								<div className={styles.businessAboutWrapper}>
									<span className={styles.businessName}>
										{typeof story.name === "string"
											? story.name.replace(/\n/, " ")
											: story.name}
									</span>
									<p className={styles.businessDetails}>{story.details}</p>
								</div>
							</div>
						</div>
					</noindex>

					{(story.link?.href || isTypeCompact) && (
						<div className={styles.businessActionsWrapper}>
							{story.link?.href && (
								<AdaptiveLink
									className={styles.businessReadMoreButton}
									href={story.link.href}
									useGatsbyLink={story.link.useGatsbyLink}
									target={story.link.target}
								>
									<>
										{data.readMore}
										<ButtonArrow />
									</>
								</AdaptiveLink>
							)}

							{isTypeCompact &&
								data.businessType.length > 1 &&
								renderArrowsInCompact()}
						</div>
					)}
				</article>

				<figure className={classNames(styles.businessIllustration, {[styles.businessIllustration__kz]: localizationContext.locale === 'ru-KZ'} || localizationContext.locale === 'kz-KZ')}>
					{story.photo}

					{story.photoCaption && (
						<figcaption
							className={classNames(
								styles.businessPhotoCaption,
								{
									[styles.businessPhotoCaption_primary]:
										story.bubbleType === BUBBLES_TYPES.PRIMARY ||
										!story.bubbleType,
								},
								{
									[styles.businessPhotoCaption_secondary]:
										story.bubbleType === BUBBLES_TYPES.SECONDARY,
								},
								{
									[styles.businessPhotoCaption_tertiary]:
										story.bubbleType === BUBBLES_TYPES.TERTIARY,
								},
								{
									[styles.businessPhotoCaption_quaternary]:
										story.bubbleType === BUBBLES_TYPES.QUATERNARY,
								},
								{
									[styles.businessPhotoCaption_pyateric]:
										story.bubbleType === BUBBLES_TYPES.PYATERIC,
								},
								{
									[styles.businessPhotoCaption_hex]:
										story.bubbleType === BUBBLES_TYPES.HEX,
								},
								{
									[styles.businessPhotoCaption_linear]:
										story.bubbleType === BUBBLES_TYPES.LINEAR,
								}
							)}
						>
							{story.photoCaption}
						</figcaption>
					)}
				</figure>
			</div>
		</section>
	)
}
