import * as React from "react"
import { SVGProps } from "react"

const ButtonArrow = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={18}
		height={18}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="m7.25 5.5 3.5 3.5-3.5 3.5"
			stroke="#3021E5"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default ButtonArrow
