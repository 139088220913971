import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 490,
			tabName: "Эл. меню",
			desktopTitle: "Электронное меню для фестивалей еды",
			desktopContent: (
				<p>
					Электронное меню полностью заменяет бумажную версию. Размести ссылку
					на&nbsp;QR-меню в&nbsp;соцсетях и&nbsp;на&nbsp;онлайн-картах города.
					Не&nbsp;нужно ждать, пока меню с&nbsp;новым блюдом выйдет
					из&nbsp;печати. А&nbsp;с&nbsp;акциями можно экспериментировать хоть
					каждый день. Онлайн-меню не&nbsp;выбьется из&nbsp;общей айдентики:
					добавь цвета своего бренда, логотип и&nbsp;фотостиль.
				</p>
			),
			detailLink: pagesLinks.wlShop,
			mediaContent: (
				<StaticImage
					className={mediaContent.image1}
					src="./assets/stadium-menu-1.png"
					alt="электронное меню для фестиваля еды"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Приложение",
			desktopTitle: "Работай с гостями напрямую через приложение",
			desktopContent: (
				<p>
					Запусти брендированное приложение&nbsp;&mdash; быстро, бесплатно, без
					найма разработчиков. Гости смогут пользоваться бонусной программой,
					делать заказы на&nbsp;доставку. Оставаться с&nbsp;ними на&nbsp;связи
					просто &mdash;&nbsp;об&nbsp;акциях и&nbsp;предложениях можно
					оповестить через push-уведомления.
				</p>
			),
			detailLink: pagesLinks.indexWL,
			mediaContent: (
				<StaticImage
					className={mediaContent.image2}
					src="./assets/stadium-menu-2.png"
					alt="мобильное приложение для заказа еды"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Карты лояльности",
			desktopTitle: "Подключай клиентов онлайн",
			desktopContent: (
				<p>
					Предложи клиентам оформить электронные карту лояльности в&nbsp;Apple
					Wallet или Google Pay. Системный подход к&nbsp;бонусным программам
					и&nbsp;CRM доступен на&nbsp;любом тарифе Quick Resto&nbsp;&mdash;
					данные гостя и&nbsp;информация о&nbsp;заказах автоматически
					сохраняются в&nbsp;CRM. Ссылку для оформления карты можно разместить
					в&nbsp;соцсетях или на&nbsp;сайте.
				</p>
			),
			detailLink: pagesLinks.wlShop,
			mediaContent: (
				<StaticImage
					className={mediaContent.image3}
					src="./assets/stadium-menu-3.png"
					alt="электронные карты лояльности"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}
