import { MutableRefObject, useEffect } from 'react'
import exportedStyles from '../styles/_V2/export.module.scss'

export function useHorizontalScrollOnWheel(
    containerRef : MutableRefObject<HTMLElement | null>,
    scrollBehavior : ScrollBehavior = 'smooth',
) : void {
    useEffect(
        () => {
            
            const element = containerRef.current
            
            if (!element) {
                return () => {
                }
            }
            
            const scrollListener = (event : WheelEvent) : void => {
                event.preventDefault()
                
                element.scrollTo({
                    behavior: scrollBehavior,
                    left: element.scrollLeft + event.deltaY,
                })
                
            }
            
            if (window.innerWidth <= +exportedStyles.verticalOrientedBreakpoint) {
                element.addEventListener('wheel', scrollListener)
            } else {
                element.removeEventListener('wheel', scrollListener)
            }
            
            return () => {
                element.removeEventListener('wheel', scrollListener)
            }
        },
    )
}